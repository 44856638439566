import axios from 'axios';
//import {MessageBox} from "element-ui";
import config from './config.js'

let service = axios.create({
  baseURL: config.host+'/web'//后端路径
});

//请求拦截器
service.interceptors.request.use((config) => {

  return config;
}, function (error) {
  return Promise.reject(error);
});

//响应拦截器
service.interceptors.response.use((res) => {

  return res;
}, (error) => {
  return Promise.reject(error);
});


let get = (url, param = {}, contentType = 'application/json;charset=utf-8') => {
  // get请求映射params参数
  url = url + '?';
  for (const propName of Object.keys(param)) {
    const value = param[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          let params = propName + '[' + key + ']';
          var subPart = encodeURIComponent(params) + "=";
          url += subPart + encodeURIComponent(value[key]) + "&";
        }
      } else {
        url += part + encodeURIComponent(value) + "&";
      }
    }
  }
  url = url.slice(0, -1);
  return new Promise((resolve, reject) => {
    service.get(url,{headers: {'Content-Type': contentType}}).then(res => {
      let result = res.data;
      resolve(result);
    }).catch(res => {
      reject(res);
    });
  });
};

let post = (url, param = {}, contentType = 'application/json;charset=utf-8') => {
  return new Promise((resolve, reject) => {
    service.post(url, param, {headers: {'Content-Type': contentType}}).then(res => {
      let result = res.data;
      resolve(result);
    }).catch(res => {
      reject(res);
    });
  });
};

let put = (url, param = {}, contentType = 'application/json;charset=utf-8') => {
  return new Promise((resolve, reject) => {
    service.put(url, param, {headers: {'Content-Type': contentType}}).then(res => {
      let result = res.data;
      resolve(result);
    }).catch(res => {
      reject(res);
    });
  });
};

let delet = (url, param = {}, contentType = 'application/json;charset=utf-8') => {
  return new Promise((resolve, reject) => {
    service.delete(url, param, {headers: {'Content-Type': contentType}}).then(res => {
      let result = res.data;
      resolve(result);
    }).catch(res => {
      reject(res);
    });
  });
};

export {get, post, put, delet}
