import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from "@/views/index";
import Article from "@/views/article";
import Column from "@/views/column";
import VR from "@/views/vr";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/article/:articleId',
    name: 'Article',
    component: Article
  },
  {
    path: '/column/:columnId/:subId',
    name: 'Column',
    component: Column
  },
  {
    path: '/vr',
    name: 'VR',
    component: VR
  }
]

const router = new VueRouter({
  routes
})

export default router
