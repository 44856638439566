<template>
	<div class = "btn" :class = "active?'active':'inactive'" @click = "handleClick">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props:['active'],
	data(){
		return {
			
		}
	},
	methods:{
		handleClick(e){
			this.$emit('click',e);
		}
	}
}
</script>

<style scoped>
.btn{
	min-width: 100px;
	height:40px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s;
	padding: 0px 10px;
}

.btn.active{
	color:white;
	background-color: #A1BAE1;
}
.btn.inactive{
	color:#A1BAE1;
	background-color: white;
}
.btn.inactive:hover{
	color:#A1BAE1;
	background-color: #b7d5ff44;
}
</style>