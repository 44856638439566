<template>
	<div id="scrollContainer" style="position:relative;height: 100vh;overflow: auto;">
		<navbar style="position: absolute;top:0;z-index: 10;"></navbar>
		<router-view style="position: relative;z-index: 1;"></router-view>
		<bottom></bottom>

		<!-- 右下按钮 -->

		<div style="position: fixed;bottom:90px;right:40px;z-index: 10;">
			
			<el-button type="primary" icon="el-icon-s-home" circle @click="routerPush('/')" v-show = "showGoHome"></el-button>

			<div style="height:10px">
			
			</div>
			
			<div>
				<el-popover placement="left-start" width="160" trigger="hover">
					<div>
						联系电话
					</div>
					<div>
						0535-5642467
					</div>
					<el-button slot="reference" type="primary" icon="el-icon-phone" circle></el-button>
				</el-popover>
			</div>
			<div style="height:10px">

			</div>
			<div>
				<el-popover placement="left-start" width="160" trigger="hover">
					<div style = "display: flex;align-items: center;justify-content: center;flex-direction: column;">
						<div>
							微信公众号
						</div>
						<el-image style="width:150px;height:150px;" src="images/wechat_qr_code.jpg" fit="fill"></el-image>
					</div>
					
					<el-button slot="reference" type="primary" icon="el-icon-s-grid" circle></el-button>
				</el-popover>
			</div>
		</div>
		<el-backtop target="#scrollContainer" :bottom="40" :right="40">
			<el-button type="primary" icon="el-icon-caret-top" circle></el-button>
		</el-backtop>
	</div>
</template>

<script>
	import navbar from "@/components/navbar"
	import bottom from "@/components/bottom"
	export default {
		name: 'app',
		components: {
			navbar,
			bottom
		},
		computed:{
			showGoHome(){
				if(this.$route.fullPath == "/"){
					return false;
				}
				return true;
			}
		},
		data() {
			return {

			}
		},
		methods:{
			routerPush(url) {
				if (this.$route.fullPath == url) {
					return; //防止报“avoid redundant route”
				}
				this.$router.push(url);
			},
		}
	}
</script>

<style>
	* {
		padding: 0px;
		margin: 0px;
	}

	/* 全局滚动条样式统一 */
	::-webkit-scrollbar {
		width: 10px;
		/*滚动条宽度*/
		height: 10px;
		/*滚动条高度*/
	}

	/*定义滚动条轨道 内阴影+圆角*/
	::-webkit-scrollbar-track {
		box-shadow: 0px 1px 3px transparent inset;
		/*滚动条的背景区域的内阴影*/
		border-radius: 10px;
		/*滚动条的背景区域的圆角*/
		background-color: transparent;
		/*滚动条的背景颜色*/
	}

	/*定义滑块 内阴影+圆角*/
	::-webkit-scrollbar-thumb {
		box-shadow: 0px 1px 3px rgba(144, 147, 153, .3) inset;
		/*滚动条的内阴影*/
		border-radius: 10px;
		/*滚动条的圆角*/
		background-color: rgba(144, 147, 153, .3);
		/*滚动条的背景颜色*/
	}
</style>