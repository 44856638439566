//字符串是否是空
let isEmpty = (string) => {
    string = stringCovertEmpty(string);
    return string === '';
}

let isNotEmpty = (string) => {
    string = stringCovertEmpty(string);
    return string !== '';
}

let stringCovertEmpty = (string)=>{
    if (!string || string === 'undefined' || string === 'null') {
        return '';
    }
    return string;
}

export {isEmpty, isNotEmpty, stringCovertEmpty}

