<template>
	<div style="background-color: #F5F5F5;border-top: 2px solid #DDDDDD;
				display: flex;align-items: center;justify-content: center;padding: 20px 0px;">
		<el-row style="width:100%;color:#535353;font-size:14px;line-height:25px;text-overflow: ellipsis;">
			<el-col :md="4">
				&nbsp;
			</el-col>
			<el-col :md="4"
				style="display: flex;align-items: center;justify-content: center;flex-direction:column;padding: 20px;">
				<div>
					<div class = "titles">蓬莱市渤海燃气有限公司</div>
					<div>地址：山东省蓬莱市北关路187号</div>
					<div>邮箱：{{overall.overallEmail}}</div>
					<div>联系电话：{{overall.overallPhone}}</div>
					<div>24小时抢险服务热线：</div>
					<div>0535-5627111,0535-5631661</div>
					<div>传真：{{overall.overallFax}}</div>
					<div style ="cursor: pointer;" @click = "windowOpen('https://beian.miit.gov.cn/#/Integrated/index')">鲁ICP备{{ overall.overallIcp }}号</div>
				</div>
			</el-col>
			<el-col :md="4" style="display: flex;align-items: center;justify-content: center;flex-direction:column;padding: 20px;">
				<el-image style="width:150px;height:150px;" src="images/wechat_qr_code.jpg" fit="fill">
					<div slot="error" style = "display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
						<i class="el-icon-picture-outline"></i>
					</div>
				</el-image>
			</el-col>
			<el-col :md="4" style="display: flex;align-items: center;justify-content: center;flex-direction:column;padding: 20px;">
				<div>
					<div class = "titles">本站导航</div>
					<div @click="routerPush(column.path)"  class = "hoverCSS" v-for="column in columns"
						:key="column.columnId">{{column.columnName}}</div>
				</div>
			</el-col>
			<el-col :md="4" style="display: flex;align-items: center;justify-content: center;flex-direction:column;padding: 20px;">
				<div>
					<div class = "titles">友情链接</div>
					<div @click="windowOpen('https://www.wenjuan.com/s/UZBZJvwGz/')" class = "hoverCSS">
						蓬莱市渤海管道燃气有限公司工商业用户报装平台</div>
					<div @click="windowOpen('http://www.bohaihotel.cn')" class = "hoverCSS">蓬莱渤海大酒店</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		get
	} from "@/utils/request";
	import {
		handleOverall
	} from "@/utils/handle";
	import config from "@/utils/config";

	export default {
		data() {
			return {
				overall: {},
				columns: []
			}
		},
		created() {
			this.getOverall();
			this.getColumns();
		},
		methods: {
			routerPush(url) {
				if (this.$route.fullPath == url) {
					return; //防止报“avoid redundant route”
				}
				//拼接一个毫秒值，是为了column页面能监听到路由变化
				this.$router.push(url+`?time=${new Date().getMilliseconds()}`);
			},
			windowOpen(url) {
				window.open(url);
			},
			//获取基本信息
			async getOverall() {
				let overall = {};
				try {
					let param = {
						params: {
							deptId: "001"
						}
					};
					let res = await get(config.addr + '/web/public/overall/table', param);
					if (res.data) {
						handleOverall(res.data);
						if (res.data.length) {
							overall = res.data[0]
						}
					}
				} catch (e) {
					console.error(e)
				} finally {
					this.overall = overall;
				}
			},
			async getColumns() {
				let columns = [];
				try {
					let param = {
						orderByColumn: "columnOrder",
						isAsc: "asc",
						params: {
							deptId: "001"
						}
					};
					let res = await get(config.addr + "/web/public/column/table", param);
					if (res.data) {
						for (let column of res.data) {
							column.path = `/column/${column.columnId}/0`;
						}
						columns = res.data;
					}
				} catch (e) {
					console.error(e);
				} finally {
					this.columns = columns;
				}
			}
		}
	}
</script>

<style scoped>
	.titles{
		font-size: 16px;
		margin:10px 0px;
	}
	.hoverCSS{
		cursor:pointer;
	}
	.hoverCSS:hover{
		color:#0055ff;
	}
</style>