<template>
	<div style="width: 100%;background-color: #ffffff88;">
		<el-row>
			<el-col :md="4">
				<!-- 居中辅助 -->
				&nbsp;
			</el-col>
			<el-col :md="2" :sm="12" :xs="12">
				<div style='cursor: pointer;display: flex;align-items: center;justify-content: center;height: 70px;'>
					<el-image @click="routerPush('/')" style="width: 70px; height: 70px" :src="logoSrc"
						fit="contain"></el-image>
				</div>
			</el-col>
			<el-col :md="3" :sm="12" :xs="12">
				<div @click="routerPush('/')" style='cursor: pointer;display: flex;align-items: center;height: 70px;color:#13499d;
					justify-content: center;text-align:center;font-size: 14px;'>
					{{overall.overallName}}
				</div>
			</el-col>
			<el-col :md="15" :sm="24" :xs="24">
				<el-row>
					<el-col :md="2" :sm="12" :xs="12" v-for="column in columns" :key="column.columnId">
						<div style="width: 100%;display: flex;align-items: center;justify-content: center;">
							<div v-if="column.columnName == '首页'" class="columnCSS" @click="routerPush(column.path)">
								{{column.columnName}}
							</div>
							<el-dropdown v-else placement="bottom">
								<div class="columnCSS" @click="routerPush(column.path)">
									{{column.columnName}}
								</div>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item v-for="sub in column.subs"
										:key="sub.subId">
										<div  @click="routerPush(sub.path)">
											{{sub.subName}}
										</div>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		get
	} from "@/utils/request";
	import {
		handleOverall
	} from "@/utils/handle";
	import config from "@/utils/config";
	import 'element-ui/lib/theme-chalk/display.css';

	export default {
		name: "navbar",
		data() {
			return {
				logoSrc: "",
				overall: {},
				columns: [],
			}
		},
		created() {
			this.getOverall();
			this.getColumnsAndSubs();
		},
		methods: {
			routerPush(url) {
				if (this.$route.fullPath == url) {
					return; //防止报“avoid redundant route”
				}
				//拼接一个毫秒值，是为了column页面能监听到路由变化
				this.$router.push(url+`?time=${new Date().getMilliseconds()}`);
			},
			async getColumnsAndSubs() {
				let columns = [];
				let subs = [];
				try {
					{
						let param = {
							orderByColumn: "columnOrder",
							isAsc: "asc",
							params: {
								deptId: "001"
							}
						};
						let res = await get(config.addr + "/web/public/column/table", param);
						if (res.data) {
							columns = [{
								columnId: "index",
								columnName: "首页",
								path: "/",
								subs: []
							}];
							for (let column of res.data) {
								column.path = `/column/${column.columnId}/0`;
								column.subs = [];
							}
							columns.push(...res.data);
						}
					}

					{
						let param = {
							orderByColumn: "subOrder",
							isAsc: 'asc'
						};
						let res = await get(config.addr + "/web/public/sub/table", param);
						if (res.data) {
							for (let sub of res.data) {
								sub.path = `/column/${sub.subColumn.columnId}/${sub.subId}`;
							}
							subs = res.data
						}
					}

					for (let column of columns) {
						let _subs = [];
						for (let sub of subs) {
							if (sub.subColumn.columnId == column.columnId) {
								_subs.push(sub);
							}
						}
						column.subs = _subs;
					}
				} catch (e) {
					console.error(e);
				} finally {
					this.columns = columns;
				}
			},
			//获取基本信息
			async getOverall() {
				let overall = {}
				let logoSrc = "";
				try {
					let param = {
						params: {
							deptId: "001"
						},
					};
					let res = await get(config.addr + "/web/public/overall/table", param);

					if (res.data) {
						handleOverall(res.data);
						if (res.data[0]) {
							overall = res.data[0];
							if (overall.overallLogoCells.length) {
								logoSrc = overall.overallLogoCells[0].url;
							}

						}
					}
				} catch (e) {
					console.error(e)
				} finally {
					this.logoSrc = logoSrc;
					this.overall = overall;
				}
			},
		}
	}
</script>

<style scoped>
	.columnCSS {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 70px;
		cursor: pointer;
		text-align: center;
		font-size: 14px;
		color: #4c4c4c;
	}

	.columnCSS:hover {
		color: #00007f;
	}
</style>