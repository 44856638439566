//接口数据处理
import config from "./config";
import {stringCovertEmpty} from "./empty";

//处理文章
let handleArticle = (list) => {//传res.data
    if (Array.isArray(list))
        for (let item of list) {
			item.articleContent = item.articleContent ? handleImgSrc(item.articleContent, true) : '';
			item.articleItem = stringCovertEmpty(item.articleItem);
			item.cells = handleCell([item.articleFolder]);
        }
}
//官网基础信息
let handleOverall = (list) => {
    if (Array.isArray(list))
      for (let item of list) {
        item.overallQrCells = item.overallQr ? handleCell([item.overallQr]):[];
        item.overallLogoCells = item.overallLogo ? handleCell([item.overallLogo]):[];
        item.overallDeptArr = item.overallDept ? item.overallDept.ancestors.split(',') : [];
  
      }
}
//处理横幅
let handleBanner = (list) => {
    if (Array.isArray(list))
      for (let item of list) {
        
        item.cells = handleCell([item.bannerFolder]);
        
        item.bannerDeptArr = item.bannerDept ? item.bannerDept.ancestors.split(',') : [];
  
      }
}
/*****************
 * 具体属性处理
 *****************/

//处理实体中的文件
let handleCell = (list) => {
	let cells = [];
	if (Array.isArray(list))
		for (let item of list){
			if (item){
				let cell = {
					folderId: item.folderId,
					url: config.host + item.folderPath
				};
				cells.push(cell);
			}
		}
	return cells;
}

//处理富文本内容中的图片
let handleImgSrc = (content, flag) => {
    let imgReg = /<img.*?(?:>|\/>)/gi;//匹配img标签
    let srcReg = /src=['"]?([^'"]*)['"]?/i;//匹配src
    let imgs = content.match(imgReg)  //筛选出所有的img
    let arr = [];
    if (Array.isArray(imgs)){
        for (let img of imgs){
            let srcs = img.match(srcReg);
            if (Array.isArray(srcs))
                arr.push(srcs[1]);
        }
        for (let s of arr){
            if (flag)
                content = content.replace(s, config.host + s);
            else
                content = content.replace(s, s.substring(s.indexOf(config.host) + config.host.length));
        }
    }
    return content;
}

export {
    handleArticle,
	//具体属性处理
	handleCell, handleImgSrc,handleOverall,handleBanner
};