<template>
	<div>
		<div style="height: 70px"></div>
		<el-image
			style="width:100%;height:330px;"
			:src="banner.picUrl"
			fit="cover">
			<div slot="error" style = "display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
				<i class="el-icon-picture-outline"></i>
			</div>
		</el-image>
		
		<el-row>
			<el-col :md = "4">
				&nbsp;
			</el-col>
			<el-col :md = "16">
				<div style = "color:#0094de;text-align: center;margin: 20px;
					font-size: 25px;font-weight: bold;">
					{{article.articleTitle}}
				</div>
				<div v-html="article.articleContent" style = "padding: 20px;"></div>
				<div style = "height:20px"></div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { get } from "../utils/request.js";
	import { handleBanner,handleArticle } from "../utils/handle";
	import config from "@/utils/config.js";
	
	export default {
		name: 'articleDetail',
		data() {
			return{
				articleId:"",
				banner: {},
				article:{}
			}
		},
		created() {
			this.articleId = this.$route.params.articleId; 
			this.getBanner();
			this.getArticle();
		},
		methods: {
			async getArticle() {
				let article = {};
				try{
					let {articleId} = this;
					if(articleId){
						let res = await get(config.addr+"/web/public/article/"+articleId);
						if(res.data){
							handleArticle([res.data]);
							article = res.data;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.article = article;
				}
			},
			async getBanner() {
				let banner = {};
				try{
					let param = { 
						bannerKind: "two", 
						params: { 
							deptId:"001",
						}
					};
					let res = await get(config.addr+"/web/public/banner/table",param);
					if(res.data){
						handleBanner(res.data);
						if(res.data.length){
							banner = res.data[0];
							let picUrl = ""
							if(banner.cells){
								if(banner.cells.length){
									picUrl = banner.cells[0].url
								}
							}
							banner.picUrl = picUrl;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.banner = banner;
				}
			},
		},
	}
	
</script>

<style>
	
</style>