<template>
	<div>
		<!-- 轮播图 -->
		<el-carousel :interval="4000" height="400px">
			<el-carousel-item v-for="banner in banners" :key="banner.bannerId">
				<el-image
					style="width:100%;height:100%;"
					:src="banner.cells[0].url"
					fit="cover">
					<div slot="error" style = "display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
						<i class="el-icon-picture-outline"></i>
					</div>
				</el-image>
			</el-carousel-item>
		</el-carousel>
		<div style = "height:10px;">
			
		</div>
		<!-- 内容 -->
		<el-row>
			<el-col :md="4">
				&nbsp;
			</el-col>
			<el-col :md="10" style = "padding:20px;">
				<div class = "sectionTitles">
					渤海燃气集团
				</div>
				<div style = "margin: 10px 0px;border-bottom:1px solid #E6E6E6;">
					<tabButton :active = "true">企业简介</tabButton>
					<tabButton :active = "false" @click = "routerPush('/vr')">VR全景</tabButton>
				</div>
				<el-row style = "cursor: pointer;">
					<el-col :md="12" style = "padding:10px">
						<div style = "display: flex;align-items:center;justify-content:center;">
							<el-image
								style="height:200px;"
								:src="article_qiYeJianJie.cells[0].url"
								fit="contain">
								<div slot="error" style = "display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
						</div>
					</el-col>
					<el-col :md="12" style = "padding:10px">
						<div	
							@click = "routerPush(`/column/${getSubBySubName('企业简介').subColumn.columnId}/${getSubBySubName('企业简介').subId}`)"
							style = "height:200px;overflow:hidden;
							font-size:13px;line-height:20px;color:#626262;">
							{{getTextFromHTML(article_qiYeJianJie.articleContent)}}
						</div>
					</el-col>
				</el-row>
			</el-col>
			<el-col :md="6" style = "padding:20px;">
				<div class = "sectionTitles">
					董事长寄语 
				</div>
				<div 
					@click = "routerPush(`/column/${getSubBySubName('董事长寄语').subColumn.columnId}/0`)"
					class = "textBtns">
					进入关于企业
				</div>
				<div>
					<div style = "width:100%;display: flex;align-items:center;justify-content:center;">
						<el-image
							style="height:180px;"
							:src="article_dongShiZhang.cells[0].url"
							fit="contain">
							<div slot="error" style = "display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
								<i class="el-icon-picture-outline"></i>
							</div>
						</el-image>
					</div>
					<div style = "height:10px;"></div>
					<div 
						@click = "routerPush(`/column/${getSubBySubName('董事长寄语').subColumn.columnId}/${getSubBySubName('董事长寄语').subId}`)"
						style = "height:60px;overflow:hidden;cursor: pointer;
						font-size:13px;line-height:20px;color:#626262;">
						{{getTextFromHTML(article_dongShiZhang.articleContent)}}
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row>
			<el-col :md="4">
				&nbsp;
			</el-col>
			<el-col :md="10" style = "padding:20px;">
				<div class = "sectionTitles">
					新闻 . 风采 . 活动
				</div>
				<div style = "margin: 10px 0px;border-bottom:1px solid #E6E6E6;">
					<tabButton @click = "xinWenTabClick('gongSiXinWen')" :active = "xinWenTabStatus.gongSiXinWen">公司新闻</tabButton>
					<tabButton @click = "xinWenTabClick('wenHuaHuoDong')"  :active = "xinWenTabStatus.wenHuaHuoDong">文化活动</tabButton>
					<tabButton @click = "xinWenTabClick('yuanGongJiaYuan')"  :active = "xinWenTabStatus.yuanGongJiaYuan">员工家园</tabButton>
				</div>
				<div style ="height:280px">
					<el-row v-show = "xinWenTabStatus.gongSiXinWen">
						<el-col :md="12">
							<el-carousel :interval="4000" height="230px" indicator-position="none">
								<el-carousel-item v-for="article in getCarouselArticlesWithPic(articles_gongSiXinWen)" :key="article.articleId">
									<div style = "position: relative;width:100%;height:100%;cursor: pointer;"
										@click = "routerPush(`/article/${article.articleId}`)">
										<el-image
											style="width:100%;height:100%"
											:src="article.picSrc"
											fit="cover">
											<div slot="error" style = "display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
												<i class="el-icon-picture-outline"></i>
											</div>
										</el-image>
										<div style = "position: absolute;bottom:0px;background-color: #00000088;width:100%;color:white;padding:5px 10px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;word-break: keep-all;">
											{{article.articleTitle}}
										</div>
									</div>
								</el-carousel-item>
							</el-carousel>
							<div style = 'height:10px'>
								
							</div>
						</el-col>
						<el-col :md="12">
							<div v-for="(article,index) in articles_gongSiXinWen" 
								:key = "index"
								style = "height:80px;padding: 0px 10px;cursor: pointer;" >
								<div style = "margin: 5px;"
									@click = "routerPush(`/article/${article.articleId}`)">
									<div style = "display: flex;align-items: center;justify-content: space-between;">
										<div style = "color:#4982db;font-size: 14px;font-weight: bold;white-space: nowrap;word-break: keep-all;text-overflow: ellipsis;overflow: hidden;" :title="article.articleTitle">{{article.articleTitle}}</div>
										<div style = "color:#626262;font-size: 14px;white-space: nowrap;word-break: keep-all;">{{article.articleDate}}</div>
									</div>
									<div v-html = "getTextFromHTML(article.articleContent)" style = "overflow: hidden;height:50px;line-height: 25px;color:#626262;font-size: 13px;">
										
									</div>
								</div>
							</div>
						</el-col>
					</el-row>
					<div v-show = "xinWenTabStatus.wenHuaHuoDong">
						<div class = "textBtns"
							@click = "routerPush(`/column/${getSubBySubName('文化活动').subColumn.columnId}/${getSubBySubName('文化活动').subId}`)">
							进入文化活动
						</div>
						<div v-for="(article,index) in articles_wenHuaHuoDong"
							:key = "index"
							@click = "routerPush(`/article/${article.articleId}`)"
							style = "padding: 10px;cursor: pointer;width: 100%;color:#626262;
									display: flex;align-items: center;justify-content: space-between;" >
								<div class = "textHoverCSS" style = "font-size: 14px;white-space: nowrap;word-break: keep-all;text-overflow: ellipsis;overflow: hidden;" :title="article.articleTitle">{{article.articleTitle}}</div>
								<div style = "font-size: 14px;white-space: nowrap;word-break: keep-all;width: 50%;text-align: right;">{{article.articleDate}}</div>
						</div>
					</div>
					<div v-show = "xinWenTabStatus.yuanGongJiaYuan">
						<div class = "textBtns"
							@click = "routerPush(`/column/${getSubBySubName('员工家园').subColumn.columnId}/${getSubBySubName('员工家园').subId}`)">
							进入员工家园
						</div>
						<div v-for="(article,index) in articles_yuanGongJiaYuan"
							:key = "index"
							@click = "routerPush(`/article/${article.articleId}`)"
							style = "padding: 10px;cursor: pointer;width: 100%;color:#626262;
									display: flex;align-items: center;justify-content: space-between;" >
								<div class = "textHoverCSS" style = "font-size: 14px;white-space: nowrap;word-break: keep-all;text-overflow: ellipsis;overflow: hidden;" :title="article.articleTitle">{{article.articleTitle}}</div>
								<div style = "font-size: 14px;white-space: nowrap;word-break: keep-all;width: 50%;text-align: right;">{{article.articleDate}}</div>
						</div>
					</div>
				</div>
			</el-col>
			<el-col :md="6" style = "padding:20px;">
				<div class = "sectionTitles">
					便民服务
				</div>
				<div style = "margin: 10px 0px;border-bottom:1px solid #E6E6E6;">
					<tabButton @click = "bianMinTabClick('ranQiAnQuan')" :active = "bianMinTabStatus.ranQiAnQuan">燃气安全</tabButton>
					<tabButton @click = "bianMinTabClick('bianMinFuWu')" :active = "bianMinTabStatus.bianMinFuWu">便民服务</tabButton>
				</div>
				<div style ="height:280px">
					<div v-show = "bianMinTabStatus.ranQiAnQuan">
						<div 
							@click = "routerPush(`/column/${getSubBySubName('燃气安全').subColumn.columnId}/${getSubBySubName('燃气安全').subId}`)"
							class = "textBtns">
							进入燃气安全
						</div>
						<div 
							@click = "routerPush('/article/'+article.articleId)"
							v-for="(article,index) in articles_ranQiAnQuan"
							:key = "index"
							style = "padding: 10px;cursor: pointer;width: 100%;color:#626262;
									display: flex;align-items: center;justify-content: space-between;" >
								<div class = "textHoverCSS" style = "font-size: 14px;white-space: nowrap;word-break: keep-all;text-overflow: ellipsis;overflow: hidden;" :title="article.articleTitle">{{article.articleTitle}}</div>
								<div style = "font-size: 14px;white-space: nowrap;word-break: keep-all;width: 50%;text-align: right;">{{article.articleDate}}</div>
						</div>
					</div>
					<div v-show = "bianMinTabStatus.bianMinFuWu">
						<div 
							@click = "routerPush(`/column/${getSubBySubName('便民服务').subColumn.columnId}/${getSubBySubName('便民服务').subId}`)"
							class = "textBtns">
							进入便民服务
						</div>
						<div 
							@click = "routerPush('/article/'+article.articleId)"
							v-for="(article,index) in articles_bianMinFuWu"
							:key = "index"
							style = "padding: 10px;cursor: pointer;width: 100%;color:#626262;
									display: flex;align-items: center;justify-content: space-between;" >
								<div class = "textHoverCSS" style = "font-size: 14px;white-space: nowrap;word-break: keep-all;text-overflow: ellipsis;overflow: hidden;" :title="article.articleTitle">{{article.articleTitle}}</div>
								<div style = "font-size: 14px;white-space: nowrap;word-break: keep-all;width: 50%;text-align: right;">{{article.articleDate}}</div>
						</div>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-row style = "padding:20px;">
			<el-col :md="4">
				&nbsp;
			</el-col>
			<el-col :md="16">
				<div class = "sectionTitles">
					集团业务
				</div>
				<el-row>
					<el-col v-for = "article in articles_jiTuanYeWu" :key = "article.articleId" :md="8">
						<div 
							@click = "routerPush(`/column/${getSubBySubName(article.articleSubs[0].subName).subColumn.columnId}/${article.articleSubs[0].subId}`)"
							style = "cursor: pointer;display: flex;align-items: center;justify-content: center;flex-direction: column;padding: 5px;">
							<el-image
								style="width:100%;height:200px;"
								:src="article.picSrc"
								fit="cover">
								<div slot="error" style = "display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
									<i class="el-icon-picture-outline"></i>
								</div>
							</el-image>
							<div style = "display: flex;align-items: center;justify-content: center;width:100%;padding:5px 10px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;word-break: keep-all;">
								{{article.articleTitle}}
							</div>
						</div>
					</el-col>
				</el-row>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		get
	} from "@/utils/request";
	import {
		handleArticle,
		handleBanner
	} from "@/utils/handle";
	import config from "@/utils/config";
	import tabButton from "@/components/tab-button";
	import dayjs from "dayjs";

	export default {
		data() {
			return {
				banners: [],
				subs:[],
				
				
				article_qiYeJianJie:{cells:[{}]},
				article_dongShiZhang:{cells:[{}]},
				
				xinWenTabStatus:{
					gongSiXinWen:true,
					wenHuaHuoDong:false,
					yuanGongJiaYuan:false,
				},
				articles_gongSiXinWen:[],
				articles_wenHuaHuoDong:[],
				articles_yuanGongJiaYuan:[],
				
				bianMinTabStatus:{
					ranQiAnQuan:true,
					bianMinFuWu:false
				},
				articles_ranQiAnQuan:[],
				articles_bianMinFuWu:[],
				articles_jiTuanYeWu:[]
			}
		},
		components:{tabButton},
		created() {
			this.getBanners();
			this.getAllSubs();
		},
		methods: {
			async getBanners() {
				let banners = [];
				try {
					let param = {
						bannerKind: "one",
						params: {
							deptId: "001"
						}
					};
					let res = await get(config.addr + "/web/public/banner/table", param);
					if (res.data) {
						banners = res.data.slice(0, 6)
						handleBanner(banners);
					}
				} catch (e) {
					console.error(e)
				} finally {
					this.banners = banners;
				}
			},
			//获取所有的子栏目
			async getAllSubs() {
				let allSubs = [];
				try{
					let res = await get(config.addr+"/web/public/sub/table",{});
					if(res.data){
						allSubs = res.data;
					}
				}catch(e){
					console.error(e);
				}finally{
					this.allSubs = allSubs;
					this.getSectionDatas();
				}
			},
			//获取每个区域需要展示的数据
			getSectionDatas(){
				this.getQiYeJianJie();
				this.getDongShiZhang();
				this.getGongSiXinWen();
				this.getWenHuaHuoDong();
				this.getYuanGongJiaYuan();
				this.getRanQiAnQuan();
				this.getBianMinFuWu();
				this.getJiTuanYeWu();
			},
			getSubBySubName(subName){
				for(let sub of this.allSubs){
					if(subName == sub.subName){
						return sub;
					}
				}
			},
			async getJiTuanYeWu(){
				let articles_jiTuanYeWu = [];
				try{
					//查询集团业务栏目
					let column = {};
					{
						let param = {
							//columnName:"集团业务",//sql未加筛选
							params: {
							}
						};
						let res = await get(config.addr+"/web/public/column/table",param);
						if(res.data){
							for(let one of res.data){
								if(one.columnName == "集团业务"){
									column = one;
								}
							}
						}
					}
					if(column.columnId){
						let param = {
							pageNum:1,
							pageSize:3,
							orderByColumn:"articleOrder",
							isAsc:"desc",
							params: { 
								columnId:column.columnId,
							}
						};
						let res = await get(config.addr+"/web/public/article/table",param);
						if(res.data){
							handleArticle(res.data);
							res.data.forEach((item)=>{
								item.articleDate = item.articleDate ? dayjs(item.articleDate).format('YYYY-MM-DD') : '';
							})
							articles_jiTuanYeWu = res.data;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.articles_jiTuanYeWu = this.getCarouselArticlesWithPic(articles_jiTuanYeWu);
				}
			},
			async getBianMinFuWu(){
				let articles_bianMinFuWu = [];
				try{
					let sub = this.getSubBySubName("便民服务");
					if(sub){
						let param = { 
							pageNum:1,
							pageSize:6,
							orderByColumn:"articleDate",
							isAsc:"desc",
							params: { 
								subId:sub.subId,
							}
						};
						let res = await get(config.addr+"/web/public/article/table",param);
						if(res.data){
							handleArticle(res.data);
							res.data.forEach((item)=>{
								item.articleDate = item.articleDate ? dayjs(item.articleDate).format('YYYY-MM-DD') : '';
							})
							articles_bianMinFuWu = res.data;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.articles_bianMinFuWu = articles_bianMinFuWu;
				}
			},
			async getRanQiAnQuan(){
				let articles_ranQiAnQuan = [];
				try{
					let sub = this.getSubBySubName("燃气安全");
					if(sub){
						let param = { 
							pageNum:1,
							pageSize:6,
							orderByColumn:"articleDate",
							isAsc:"desc",
							params: { 
								subId:sub.subId,
							}
						};
						let res = await get(config.addr+"/web/public/article/table",param);
						if(res.data){
							handleArticle(res.data);
							res.data.forEach((item)=>{
								item.articleDate = item.articleDate ? dayjs(item.articleDate).format('YYYY-MM-DD') : '';
							})
							articles_ranQiAnQuan = res.data;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.articles_ranQiAnQuan = articles_ranQiAnQuan;
				}
			},
			async getYuanGongJiaYuan(){
				let articles_yuanGongJiaYuan = [];
				try{
					let sub = this.getSubBySubName("员工家园");
					if(sub){
						let param = { 
							pageNum:1,
							pageSize:6,
							orderByColumn:"articleDate",
							isAsc:"desc",
							params: { 
								subId:sub.subId,
							}
						};
						let res = await get(config.addr+"/web/public/article/table",param);
						if(res.data){
							handleArticle(res.data);
							res.data.forEach((item)=>{
								item.articleDate = item.articleDate ? dayjs(item.articleDate).format('YYYY-MM-DD') : '';
							})
							articles_yuanGongJiaYuan = res.data;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.articles_yuanGongJiaYuan = articles_yuanGongJiaYuan;
				}
			},
			async getWenHuaHuoDong(){
				let articles_wenHuaHuoDong = [];
				try{
					let sub = this.getSubBySubName("文化活动");
					if(sub){
						let param = { 
							pageNum:1,
							pageSize:6,
							orderByColumn:"articleDate",
							isAsc:"desc",
							params: { 
								subId:sub.subId,
							}
						};
						let res = await get(config.addr+"/web/public/article/table",param);
						if(res.data){
							handleArticle(res.data);
							res.data.forEach((item)=>{
								item.articleDate = item.articleDate ? dayjs(item.articleDate).format('YYYY-MM-DD') : '';
							})
							articles_wenHuaHuoDong = res.data;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.articles_wenHuaHuoDong = articles_wenHuaHuoDong;
				}
			},
			async getGongSiXinWen(){
				let articles_gongSiXinWen = [];
				try{
					let sub = this.getSubBySubName("公司新闻");
					if(sub){
						let param = { 
							pageNum:1,
							pageSize:3,
							orderByColumn:"articleDate",
							isAsc:"desc",
							params: { 
								subId:sub.subId,
							}
						};
						let res = await get(config.addr+"/web/public/article/table",param);
						if(res.data){
							handleArticle(res.data);
							res.data.forEach((item)=>{
								item.articleDate = item.articleDate ? dayjs(item.articleDate).format('YYYY-MM-DD') : '';
							})
							articles_gongSiXinWen = res.data;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.articles_gongSiXinWen = articles_gongSiXinWen;
				}
			},
			async getQiYeJianJie(){
				let article_qiYeJianJie = {};
				try{
					let sub = this.getSubBySubName("企业简介");
					if(sub){
						let param = { params: { subId:sub.subId } };
						let res = await get(config.addr+"/web/public/article/table",param);
						if(res.data){
							handleArticle(res.data);
							if(res.data.length){
								article_qiYeJianJie = res.data[0];
							}
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.article_qiYeJianJie = article_qiYeJianJie;
				}
			},
			async getDongShiZhang(){
				let article_dongShiZhang = {};
				try{
					let sub = this.getSubBySubName("董事长寄语");
					if(sub){
						let param = { params: { subId:sub.subId } };
						let res = await get(config.addr+"/web/public/article/table",param);
						if(res.data){
							handleArticle(res.data);
							if(res.data.length){
								article_dongShiZhang = res.data[0];
							}
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.article_dongShiZhang = article_dongShiZhang;
				}
			},
			xinWenTabClick(clickKey){
				for(let key in this.xinWenTabStatus){
					this.xinWenTabStatus[key] = false;
				}
				this.xinWenTabStatus[clickKey] = true;
			},
			bianMinTabClick(clickKey){
				for(let key in this.bianMinTabStatus){
					this.bianMinTabStatus[key] = false;
				}
				this.bianMinTabStatus[clickKey] = true;
			},
			//提取富文本内的文字
			getTextFromHTML(str) {
				return str?str.replace(/<[^>]+>/g, ""):"";
			},
			//提取富文本内的图片地址
			getFirstImageSrcFromHTML(htmlString) {
				let reg = new RegExp(`(<img.*src=".*?>)`); //正则表达式
				let result = "";
				if(htmlString){
					if (htmlString.match(reg)) {
						let ss = htmlString.match(reg)[0].split(`src="`);
						let ss2 = ss[1].split(`"`);
						result = ss2[0];
					}
				}
				return result;
			},
			//传入文章数组，返回带有图片地址的文章的数组
			//如果有附件图片，选择附件图片，如果没有，从富文本中提取，还没有，就不要了
			getCarouselArticlesWithPic(articles){
				let carouselArticles = [];
				for(let article of articles){
					let picSrc = "";
					let {cells} = article;
					for(let cell of cells){
						if(cell.url){
							picSrc = cell.url;
						}
					}
					if(!picSrc){
						if(article.articleContent){
							let url = this.getFirstImageSrcFromHTML(article.articleContent);
							if(url){
								picSrc = url;
							}
						}
					}
					if(picSrc){
						article.picSrc = picSrc;
						carouselArticles.push(article);
					}
				}
				return carouselArticles;
			},
			routerPush(url) {
				if (this.$route.fullPath == url) {
					return; //防止报“avoid redundant route”
				}
				this.$router.push(url);
			},
		}
	}
</script>

<style scoped>
	.sectionTitles{
		font-size:22px;
		line-height:30px;
		color:#075ade;
		border-bottom: #075ade 1px solid ;
		margin-bottom: 10px;
	}
	
	.textBtns{
		color:#a1bae1;
		font-size:12px;
		float:right;
		padding:10px;
		cursor: pointer;
	}
	.textBtns:hover{
		color:#7a8daa;
	}
	.textHoverCSS:hover{
		color:#899fbf;
	}
</style>