<template>
	<div>
		<div style="height: 70px"></div>
		<el-image style="width:100%;height:330px;" :src="banner.picUrl" fit="cover">
			<div slot="error"
				style="display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
				<i class="el-icon-picture-outline"></i>
			</div>
		</el-image>

		<el-row>
			<el-col :md="4">
				&nbsp;
			</el-col>
			<el-col :md="16">
				<div style="font-size: 25px;color:#6b95d8;padding:20px;">
					{{column.columnName}}
				</div>
				<div style="margin: 10px;border-bottom:1px solid #E6E6E6;">
					<tabButton v-for="sub in subs" :key="sub.subId" :active="sub.active" @click="subClick(sub)">
						{{sub.subName}}</tabButton>
				</div>
				<div style = "position: relative;min-height: 100px;">
					<!-- 看BoHaiVueUI中的代码，这里暂时理解为：特殊子栏目直接显示第一篇文章，普通子栏目显示文章列表 -->
					<div v-show="currentSub.subType == 'common'" style="padding:20px" :v-loading="true">
						<div v-for="article in articles" :key="article.articleId"
							@click="routerPush(`/article/${article.articleId}`)" style="cursor: pointer;">
							<div style="font-size: 22px;">
								{{article.articleTitle}}
							</div>
							<div style="color:#949494;height:40px;margin:10px 0px;overflow: hidden;line-height: 20px;font-size: 14px;text-overflow: ellipsis;"
								v-html="getTextFromHTML(article.articleContent)">
							</div>
							<div style="color:#949494;font-size: 12px;">
								时间 : {{article.articleDate}}
							</div>
							<el-divider></el-divider>
						</div>
						<div  v-show = "!articleLoading&&articles.length == 0">
							<el-empty description="暂无数据"  style = "height: 200px;" :image-size="50"></el-empty>
						</div>
						<div style = "display: flex;width: 100%;align-items: center;justify-content: center;">
							<el-pagination
								@current-change = "handleCurrentChange"
								:current-page="pageNum"
								background layout="prev, pager, next" 
								:total="totalCount"
								:pager-count="5">
							</el-pagination>
						</div>
						
					</div>
					<div v-show="currentSub.subType == 'special'">
						<div v-if="articles.length">
							<div v-html="articles[0].articleContent" style="padding:20px">
					
							</div>
						</div>
						<div style="height:100px">
					
						</div>
					</div>
					<div style = 'position: absolute;width: 100%;height: 100%;
							z-index: 10;background-color: #ffffff88;top:0;left:0;
							display: flex;align-items: center;justify-content: center;
							color:#6b95d8;font-size: 30px;' v-show = "articleLoading">
						<i class = "el-icon-loading"></i>
					</div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import {
		get
	} from "../utils/request.js";
	import {
		handleBanner,
		handleArticle
	} from "../utils/handle";
	import config from "@/utils/config.js";
	import tabButton from "@/components/tab-button"
	import dayjs from "dayjs";

	export default {
		name: 'columnDetail',
		components: {
			tabButton
		},
		data() {
			return {
				banner: {},
				columnId: "",
				column: {},
				subs: [],
				subId: "",
				articles: [],

				pageNum: 1,
				totalCount: 0,
				articleLoading:false,
			}
		},
		created() {
			this.getBanner();
		},
		computed: {
			currentSub() {
				let result = {};
				for (let sub of this.subs) {
					if (sub.subId == this.subId) {
						result = sub;
					}
				}
				return result;
			}
		},
		watch: {
			$route: {
				handler(route) {
					if (
						!
						route.fullPath.startsWith('/column')

					) {
						return;
					}
					this.columnId = this.$route.params.columnId;
					this.subId = this.$route.params.subId;
					this.getColumn();
					this.getSubs();
					this.getArticles();
				},
				immediate: true
			},
			//由于本项目的结构与特殊要求，监听subId会出现问题，所以不监听
		},
		methods: {
			handleCurrentChange(e){
				this.pageNum = e;
				this.getArticles();
			},
			async getArticles() {
				let articles = [];
				let totalCount = 0;
				try {
					this.articleLoading = true;
					
					//考虑切换后分页需复位的情况
					//这里采取：检查目前的article的subId，如果发生变化，则重新加载第一页
					let currentSubId = "";
					if(this.articles.length){
						currentSubId = this.articles[0].articleSubs[0].subId
					}
					if(currentSubId!=this.subId){
						this.pageNum = 1;
					}
					
					
					//直接取currenSub会导致navbar点击子栏目时无法加载文章
					//所以这里必须重新获取sub
					let {
						subId,
						pageNum
					} = this;
					
					let sub = {}; {
						let res = await get(config.addr + `/web/public/sub/${subId}`);
						if (res.data) {
							sub = res.data;
						}
					}
					if (sub.subType == "common") {
						let param = {
							pageNum,
							pageSize: 5,
							orderByColumn: "articleDate",
							isAsc: "desc",
							params: {
								subId
							}
						};
						let res = await get(config.addr + "/web/public/article/table", param);

						if (res.data) {
							handleArticle(res.data);
							articles = res.data;
						}
						if(res.count){
							totalCount = res.count;
						}
					}
					if (sub.subType == "special") {
						let param = {
							orderByColumn: "articleOrder",
							isAsc: "asc",
							params: {
								subId
							}
						};
						let res = await get(config.addr + "/web/public/article/table", param);

						if (res.data) {
							handleArticle(res.data);
							if (res.data.length) {
								articles = [res.data[0]];
							}
						}
					}
					for (let article of articles) {
						article.articleDate = article.articleDate ? dayjs(article.articleDate).format('YYYY-MM-DD') :
							'';
					}
				} catch (e) {
					console.error(e);
				} finally {
					this.articleLoading = false;
					this.articles = articles;
					this.totalCount = totalCount;
				}
			},
			subClick(sub) {
				for (let one of this.subs) {
					one.active = false;
					if (one.subId == sub.subId) {
						one.active = true;
						this.subId = sub.subId;
						this.getArticles();
					}
				}
			},
			async getColumn() {
				let column = {};
				try {
					let {
						columnId
					} = this;
					if (columnId) {
						let res = await get(config.addr + "/web/public/column/" + columnId);
						if (res.data) {
							column = res.data;
						}
					}
				} catch (e) {
					console.error(e);
				} finally {
					this.column = column;
				}
			},
			async getSubs() {
				let subs = [];
				try {
					let {
						columnId,
						subId
					} = this;
					if (columnId) {
						let param = {
							orderByColumn: "subOrder",
							isAsc: "asc",
							params: {
								columnId
							}
						};
						let res = await get(config.addr + "/web/public/sub/table", param);
						if (res.data) {
							subs = res.data;
							//开启传入的子栏目，如果没传入，默认开启第一个
							for (let sub of subs) {
								sub.active = false;
							}
							if (subId && subId != "0") {
								for (let sub of subs) {
									if (sub.subId == subId) {
										sub.active = true;
									}
								}
							} else {
								if (subs.length) {
									subs[0].active = true;
									this.subId = subs[0].subId;
									this.getArticles();
								}
							}
						}
					}
				} catch (e) {
					console.error(e);
				} finally {
					this.subs = subs;
				}
			},
			async getBanner() {
				let banner = {};
				try {
					let param = {
						bannerKind: "two",
						params: {
							deptId: "001",
						}
					};
					let res = await get(config.addr + "/web/public/banner/table", param);
					if (res.data) {
						handleBanner(res.data);
						if (res.data.length) {
							banner = res.data[0];
							let picUrl = ""
							if (banner.cells) {
								if (banner.cells.length) {
									picUrl = banner.cells[0].url
								}
							}
							banner.picUrl = picUrl;
						}
					}
				} catch (e) {
					console.error(e);
				} finally {
					this.banner = banner;
				}
			},
			//提取富文本内的文字
			getTextFromHTML(str) {
				return str ? str.replace(/<[^>]+>/g, "") : "";
			},
			routerPush(url) {
				if (this.$route.fullPath == url) {
					return; //防止报“avoid redundant route”
				}
				this.$router.push(url);
			},
		},
	}
</script>

<style>

</style>