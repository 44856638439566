<template>
	<div>
		<div style="height: 70px"></div>
		<el-image
			style="width:100%;height:330px;"
			:src="banner.picUrl"
			fit="cover">
			<div slot="error" style = "display: flex;align-items: center;justify-content: center;width: 100%;height: 100%;color: #a1a1a1;font-size: 20px;">
				<i class="el-icon-picture-outline"></i>
			</div>
		</el-image>
			
		
		<el-row>
			<el-col :md = "4">
				&nbsp;
			</el-col>
			<el-col :md = "16">
				<div style = "font-size: 25px;color:#6b95d8;padding:20px;">
					全景预览
				</div>
				<el-tabs v-model="activeName">
					<el-tab-pane label="渤海燃气" name="tenth">
						<iframe src="http://bohaiview.lcsci.net/BoHaiQJ/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="储运站" name="first">
						<iframe src="http://bohaiview.lcsci.net/ChuYunZhanQJ/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="管道燃气" name="second">
						<iframe src="http://bohaiview.lcsci.net/GuanDaoQJ/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="第一加气站" name="third">
						<iframe src="http://bohaiview.lcsci.net/DIyiQJ/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="第二加气站" name="fourth">
						<iframe src="http://bohaiview.lcsci.net/DIerQJ/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="潮水储配站" name="fifth">
						<iframe src="http://bohaiview.lcsci.net/ChaoShui/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="大储配站" name="sixth">
						<iframe src="http://bohaiview.lcsci.net/DaChuBei/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="泓澳新能源" name="seventh">
						<iframe src="http://bohaiview.lcsci.net/HongAo/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="气瓶检测" name="eighth">
						<iframe src="http://bohaiview.lcsci.net/QiPing/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
					<el-tab-pane label="小门家站" name="ninth">
						<iframe src="http://bohaiview.lcsci.net/XiaoMen/" width="100%" height="500px"   frameborder="0"  name="myframe" scrolling="no"></iframe>
					</el-tab-pane>
				</el-tabs>
				<div style = "height: 20px;">
					
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import { get } from "../utils/request.js";
	import { handleBanner } from "../utils/handle";
	import config from "@/utils/config.js";
	export default {
		name: 'vr',
		data() {
			return{
				activeName: 'first',
				banner: {},
			}
		},
		created() {
			this.getBanner();
		},
		methods: {
			async getBanner() {
				let banner = {};
				try{
					let param = { 
						bannerKind: "two", 
						params: { 
							deptId:"001",
						}
					};
					let res = await get(config.addr+"/web/public/banner/table",param);
					if(res.data){
						handleBanner(res.data);
						if(res.data.length){
							banner = res.data[0];
							let picUrl = ""
							if(banner.cells){
								if(banner.cells.length){
									picUrl = banner.cells[0].url
								}
							}
							banner.picUrl = picUrl;
						}
					}
				}catch(e){
					console.error(e);
				}finally{
					this.banner = banner;
				}
			},
		},
	}
	
</script>

<style>
	
</style>